import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
import InputText from 'primevue/inputtext';
import Slider from 'primevue/slider';
import InputMask from 'primevue/inputmask';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';

const store = createStore({
  state: {
    user: '',
    token: '',
    tariff: 0,
    phone: '',
    amount: 0,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setTariff(state, tariff) {
      state.tariff = tariff;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setAmount(state, amount) {
      state.amount = amount;
    },
  },
  actions: {
    login({ commit }, { user, token }) {
      commit('setUser', user);
      commit('setToken', token);
    },
    loan({ commit }, { phone, tariff, amount }) {
      commit('setPhone', phone);
      commit('setTariff', tariff);
      commit('setAmount', amount);
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.use(store);
app.component('InputText', InputText);
// eslint-disable-next-line
app.component('Slider', Slider);
app.component('InputMask', InputMask);
// eslint-disable-next-line
app.component('Button', Button);
// eslint-disable-next-line
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
// eslint-disable-next-line
app.component('Column', Column);
app.component('InputNumber', InputNumber);
app.component('AutoComplete', AutoComplete);
app.component('FileUpload', FileUpload);
app.component('ProgressSpinner', ProgressSpinner);
app.component('InputSwitch', InputSwitch);
// eslint-disable-next-line
app.component('Dialog', Dialog);
// eslint-disable-next-line
app.component('Password', Password);
// eslint-disable-next-line
app.component('Listbox', Listbox);
// eslint-disable-next-line
app.component('MultiSelect', MultiSelect);

app.mount('#app');

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/',
    name: 'homeWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/login',
    name: 'loginWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/AuthorizationView.vue'),
  },
  {
    path: '/loans',
    name: 'loans',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/loans',
    name: 'loansWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/LoansView.vue'),
  },
  {
    path: '/managers',
    name: 'managers',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/managers',
    name: 'managersWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/ManagersView.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/reports',
    name: 'reportsWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/ReportsView.vue'),
  },
  {
    path: '/new_loan',
    name: 'NewLoan',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/new_loan',
    name: 'NewLoanWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/LoanView.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/help',
    name: 'helpWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/HelpView.vue'),
  },
  {
    path: '/v2/',
    name: 'v2Home',
    component: () => import('../views/NotFindView.vue'), // Предполагается, что у вас есть отдельный компонент для v2
  },
  {
    path: '/v2/:partnerId/',
    name: 'v2HomeWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('../views/v2/HomeView.vue'),
  },
  {
    path: '/v2/login',
    name: 'v2Login',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/login',
    name: 'v2LoginWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/v2/AuthorizationView.vue'),
  },
  {
    path: '/v2/loans',
    name: 'v2Loans',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/loans',
    name: 'v2LoansWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/v2/LoansView.vue'),
  },
  {
  path: '/v2/managers',
  name: 'v2Managers',
  component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/managers',
    name: 'v2ManagersWithPartner',
    props: (to) => ({
    partnerId: +to.params.partnerId,
  }),
    component: () => import('@/views/v2/UsersView.vue'),
  },
  {
    path: '/v2/groups',
    name: 'v2Groups',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/groups',
    name: 'v2GroupsWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/v2/GroupsView.vue'),
  },
  {
    path: '/v2/reports',
      name: 'v2Reports',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/reports',
      name: 'v2ReportsWithPartner',
    props: (to) => ({
    partnerId: +to.params.partnerId,
  }),
    component: () => import('@/views/v2/ReportsView.vue'),
  },
  {
    path: '/v2/new_loan',
    name: 'v2NewLoan',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/new_loan',
      name: 'v2NewLoanWithPartner',
    props: (to) => ({
    partnerId: +to.params.partnerId,
  }),
    component: () => import('@/views/v2/LoanView.vue'),
  },
  {
    path: '/v2/help',
      name: 'v2Help',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/help',
      name: 'v2HelpWithPartner',
    props: (to) => ({
    partnerId: +to.params.partnerId,
  }),
    component: () => import('@/views/v2/HelpView.vue'),
  },
  {
    path: '/v2/user',
    name: 'v2User',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/v2/:partnerId/user/:userId',
    name: 'v2UserWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
      userId: +to.params.userId,
    }),
    component: () => import('@/views/v2/UserView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
